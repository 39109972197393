body {
  margin: 0;
  font-family: 'iA Writer Quattro', system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #090909;
}

code {
  /* Keep monospace fonts for code blocks */
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} 